

const dataFaq = [
    {
        id: 1,
        title: 'Heures du défilé ?',
        content: 'Le défilé aura lieu le 31 octobre 2021 à 20h00 UTC. et durera environ 1 heure. Ca va être un spectacle incroyable! Fusce est mi, imperdiet porta neque malesuada, blandit scelerisque lectus. Maecenas vel lobortis risus. Donec ornare urna quis libero euismod, ac fermentum diam blandit. Nunc dapibus tempor lacus, vel lacinia enim efficitur eu',
    }, 
    {
        id: 2,
        title: 'Heures des lunbabus ?',
        content: '00h15: Départ du LUNBABUS devant la place de fête jusqu’à la gare de Sion, en passant par les localités suivantes: Saint-Martin, Evolène Hérémence, Vex, Sion. 3h00: Départ du LUNBABUS devant la place de fête. Saint-Martin, Evolène, Hérémence, Vex, Sion.'
    },
    {
        id: 3,
        title: 'Entrée est-elle libre ?',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus eleifend egestas. Duis eget dictum purus. Fusce est mi, imperdiet porta neque malesuada, blandit scelerisque lectus. Maecenas vel lobortis risus. Donec ornare urna quis libero euismod, ac fermentum diam blandit. Nunc dapibus tempor lacus, vel lacinia enim efficitur eu'
    },
    

]

export default dataFaq;